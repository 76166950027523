<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar flat
      >
        <v-card-title>
          <span>{{ title }} download</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="download.name"
                  :error="$v.download.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.download.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>


            <v-col cols="12">
              <div class="mb-3" v-if="file_path">
                <a class="font-weight-bold " :href="file_path" target="_blank"><i class="fas fa-file fa-2x mr-3"></i>File</a>
              </div>
              <v-file-input
                  label="Select File"
                  v-model="download.uploadFile"
                  placeholder="File"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  outlined
                  dense
              >
              </v-file-input>
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Download Category"
                  v-model="download.download_category_id"
                  :items="downloadCategories"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  @input="download.download_category_id = $event !== null ? $event : ''"
                  clearable
              >
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                  @change="resetUser"
                  v-model="download.access_type"
                  :items="accessTypes"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  :error="$v.download.access_type.$error"
                  @input="download.access_type = $event !== null ? $event : ''"
                  clearable
              >
                <template v-slot:label>
                  Access Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.download.access_type.$error">Access Type is required</span>
            </v-col>

            <v-col cols="6" v-if="download.access_type == 'group'">
              <v-select
                  v-model="download.access_group"
                  :items="accessGroups"
                  item-text="name"
                  item-value="value"
                  :error="$v.download.access_group.$error"
                  @input="download.access_group = $event !== null ? $event : ''"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Access Group <span class="text-danger">*</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="selectAllGroups"
                  >
                    <v-list-item-action>
                      <v-icon :color="download.access_group.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>

              <span class="text-danger" v-if="$v.download.access_group.$error">Access Group is required</span>
            </v-col>

            <v-col cols="12" v-if="selectedUsers.length > 0 && download.access_type == 'user'">
              <v-chip class="ma-2" @click:close="removeUser(index)" color="primary"
                      v-for="(examiner,index) in selectedUsers" :key="index"
                      label close>
                {{ examiner }}
              </v-chip>
            </v-col>
            <v-col cols="12" v-if="download.access_type == 'user'">
              <v-autocomplete
                  @change="getUser"
                  v-model="selectedUser"
                  :search-input.sync="userSearch"
                  :items="items"
                  :loading="isLoading"
                  item-text="display_text"
                  item-value="id"
                  label="Applicable Users"
                  return-object
                  hide-selected
                  clearable
                  outlined
                  dense
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Search by name </strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                :config="editorConfig"
                v-model="download.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.download.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="download.is_active"
                  :label="download.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
           x-large
           text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadService from "@/services/cms/download/DownloadService";
import DownloadCategoryService from "@/services/cms/download-category/DownloadCategoryService";
import { required, requiredIf, numeric } from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const download = new DownloadService();
const downloadCategory = new DownloadCategoryService();
const user = new AccountholderService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      isLoading: false,
      loading: false,
      file_path: null,
      downloadCategories: [],
      selectedUsers: [],
      selectedUser: {},
      users: [],
      errors: [],
      userSearch: null,
      accessTypes: [
        { name: "Group", value: "group" },
        { name: "User", value: "user" },

      ],
      accessGroups: [
        { name: "Examiner", value: "examiner" },
        { name: "Supervisor", value: "supervisor" },
        { name: "Enroller", value: "accountholder" },
        { name: "Online marker", value: "online_marker" },
        { name: "Candidate", value: "candidate" },
      ],
      download: {
        name: '',
        uploadFile: null,
        position: '',
        access_type: 'group',
        access_group: [],
        applicable_users: '',
        description: '',
        is_active: true,
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  validations: {
    download: {
      name: { required },
      access_type: { required },
      access_group: { required:requiredIf(function () {
          return this.download.access_type == 'group';
        }) },
      description: { required },
      is_active: { required }
    }
  },
  mounted() {
    this.getAllDownloadCategories();
  },
  watch: {
      userSearch (val) {
        if (this.isLoading) return;
        if(val != null) {
          user
              .getAllByUserType(val)
              .then((response) => {
                this.users =response.data.accountholders
              })
              .catch(err => {

              })
              .finally(() => (this.isLoading = false))
        }
      }
  },
  computed: {
    items() {
      return this.users.map((user, index) => {
        const display_text = user.first_name+' '+ user.last_name+ ' || '+user.email+' || '+user.unique_identifier;
        return Object.assign({}, user, { display_text });
      });
    },
    selectAllGroup () {
      return this.download.access_group.length === this.accessGroups.length
    },
    selectedGroup () {
      return this.download.access_group.length > 0 && !this.selectAllGroup
    },
    icon () {
      if (this.selectAllGroup) return 'mdi-close-box'
      if (this.selectedGroup) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    closeDialog(){
      this.dialog = false;
    },
    createDownload(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
      this.getAllDownloadCategories();
    },
    editDownload(download){
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.download = download;
      if(download.applicable_users_lists != null) {
        this.selectedUsers = download.applicable_users_lists
      }
      if(download.access_group == null) {
        this.download.access_group = [];
      }
      if(download.file != null) {
        this.file_path = download.file_path.thumb;
      }
      this.getAllDownloadCategories();
    },
    getAllDownloadCategories() {
      downloadCategory.all().then(response => {
        this.downloadCategories = response.data.downloadCategories;
      });
    },
    selectAllGroups () {
      this.$nextTick(() => {
        if (this.selectAllGroup) {
          this.download.access_group = []
        } else {
          this.download.access_group = [];
          this.accessGroups.forEach(type => {
            this.download.access_group.push(type.value);
          });
        }
      })
    },
    getUser(){
      if(this.download.applicable_users == null || this.download.applicable_users == ''){
        this.download.applicable_users = [];
      }
      if(this.selectedUser != null) {
        let full_name = this.selectedUser.first_name + ' '+ this.selectedUser.last_name;
        this.download.applicable_users.push(this.selectedUser.id);
        this.selectedUsers.push(full_name);
      }

      this.selectedUser = null;
      this.userSearch = null;
      this.users = [];
    },
    removeUser(index){
      this.download.applicable_users.splice(index,1);
      this.selectedUsers.splice(index,1);
    },
    resetUser() {
      if(this.users || this.download.applicable_users) {
        this.download.applicable_users = [];
        this.download.access_group = [];
        this.selectedUsers = [];
        this.users = [];
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.download) {
        if (key === "uploadFile" && this.download[key] != null && this.download[key] != undefined) {
          formData.append('uploadFile', this.download[key]);
        }else {
          if(this.download[key]){
            formData.append(key,this.download[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      let formData = this.convertToFormData();
      download
          .create(formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Download added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();
      download
          .update(this.download.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Download added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.selectedUsers = [];
      this.file_path = null;
      this.download = {
        name: '',
        position: '',
        score_id: '',
        access_type: 'group',
        access_group: [],
        applicable_users: '',
        description: '',
        is_active: true,
      }
    },
  }
}
</script>

<style scoped>

</style>
